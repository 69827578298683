import Text from '@atoms/Text';

const FeatureCard = ({ feature, index }) => {
    return (
        <div
            className={`px-4 pt-6 pb-8 xl:pb-10 xl:pr-10 xl:pl-6 xl:pt-6 rounded-xl bg-homepage-features-bg-gradient relative ${
                index ? '-mt-4' : 'top-0'
            }`}
            {...(feature?.dataAosProps || {})}
        >
            <Text
                content={feature.title}
                className="p3-semibold text-primary-500 pb-1"
                htmlTag='h3'
            />
            <Text
                content={feature.value}
                className={`p5-regular text-gray-900 ${
                    index === 1 ? 'mb-2 xl:mb-0' : ''
                }`}
            />
        </div>
    );
};

export default FeatureCard;
