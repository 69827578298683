import Text from '@atoms/Text';
import texts from '@components/Referral/en.json';
import FeatureCard from './FeatureCard';
import Image from '@atoms/Image';
import { Fragment } from 'react';

const Features = ({
    featuresList,
    primaryText = texts.WeDoTheHeavyLiftingForYouToMakeInvestmentEasy,
    primaryFonts = 'h5-semibold md:h4-semibold xl:h3-semibold',
    secondaryText = (
        <>
            {texts.GetAccessToCuratedRiskAssessed}
            <br className="hidden xl:block" /> {texts.Opportunities}{' '}
            {texts.DesignedForRetailInvestors}
        </>
    ),
    secondaryFonts = 'p5-regular md:p4-regular',
    breakpoint = 'md',
    topSpacing = 'md:mt-16 pt-12',
}) => {
    return (
        <div className="md:px-6 xl:px-16 relative">
            <div
                className={`z-50 relative grid grid-cols-2 gap-8 md:gap-14 ${topSpacing} md:px-6 xl:px-12 md:mb-0 items-center bg-no-repeat bg-cover pb-0 md:pb-16 mx-4 md:mx-auto rounded-3xl !max-w-7xl`}
            >
                <div
                    className={`w-full col-span-full ${breakpoint}:col-span-1`}
                >
                    <Text
                        content={primaryText}
                        className={`${primaryFonts} text-primary-500 pb-2 text-center ${breakpoint}:text-left`}
                        htmlTag='h2'
                    />
                    <Text
                        content={secondaryText}
                        className={`${secondaryFonts} mx-4 md:mx-0 text-gray-900 text-center ${breakpoint}:text-left`}
                    />
                </div>
                <div
                    className={`flex flex-col md:w-3/4 mx-auto xl:w-full rounded-xl md:bg-transparent col-span-full ${breakpoint}:col-span-1`}
                >
                    {featuresList.map((feature, index) => (
                        <Fragment key={index}>
                            <FeatureCard
                                {...{
                                    feature,
                                    index,
                                }}
                            />
                        </Fragment>
                    ))}
                </div>
            </div>

            <Image
                src="/images/homepage/secondary-rectangle-glow-right.svg"
                className={`absolute right-0 -bottom-72 block md:hidden w-[300px] z-10 md:max-w-[510px]`}
            />
        </div>
    );
};

export default Features;
